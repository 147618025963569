<template>
  <div>
    <div slot="extra">
      <div class="more-info" style="margin-bottom: 10px;">
        <a-button type="primary" @click="handleAdd">分配组织机构职务</a-button>
      </div>
    </div>
    <a-table
      :rowKey="(record) => record.id"
      :pagination="false"
      :columns="columns"
      :dataSource="tableData"
      :loading="tableLoading"
    >
      <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
      <span slot="ifDefault" slot-scope="text, record">
        <span v-if="record.ifDefault">
          <a-icon type="check-circle" theme="twoTone" twoToneColor="#1890ff" style="font-size: 24px;" />
        </span>
      </span>
      <span slot="job" slot-scope="text, record">
        {{ record.jobId ? record.jobName : '-' }}
      </span>
      <span slot="operation" slot-scope="text, record">
        <template>
          <template v-if="!record.ifDefault">
            <a-button
              type="default"
              shape="circle"
              icon="check"
              size="small"
              @click="handleDefault(record.id)"
            ></a-button>
            <a-divider type="vertical" />
          </template>
          <a-button type="default" shape="circle" icon="edit" size="small" @click="handleEdit(record.id)"></a-button>
          <template v-if="tableData.length > 1">
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除职务操作吗?" @confirm="() => handleDelete(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red;" />
              <a-button type="danger" shape="circle" icon="delete" size="small"></a-button>
            </a-popconfirm>
          </template>
        </template>
      </span>
    </a-table>
    <a-modal
      :title="chooseData.name + '的组织机构职务分配'"
      v-model="addModal"
      :maskClosable="false"
      okText="添加"
      cancelText="取消"
      width="400px"
      :confirmLoading="confirmButtonStatus"
      @ok="handleAddOk"
    >
      <user-job-form
        type="add"
        ref="addRef"
        :userId="chooseData.id"
        :chooseOrgIds="currentOrgIds"
        @submitStart="handleConfirmStart"
        @addSuccess="addSuccess"
        @addError="addError"
      ></user-job-form>
    </a-modal>
    <a-modal
      :title="chooseData.name + '的组织机构职务修改'"
      v-model="editModal"
      :maskClosable="false"
      okText="修改"
      cancelText="取消"
      width="400px"
      :confirmLoading="confirmButtonStatus"
      @ok="handleEditOk"
    >
      <user-job-form
        type="edit"
        ref="editRef"
        @submitStart="handleConfirmStart"
        @editSuccess="editSuccess"
        @editError="editError"
      ></user-job-form>
    </a-modal>
  </div>
</template>

<script>
import SERVICE_URLS from '../../../api/service.url'
import UserJobForm from './UserJobForm'
import entityCRUD from '../../../views/common/mixins/entityCRUD'

export default {
  props: {
    chooseData: {},
  },
  name: 'UserJobList',
  components: { UserJobForm },
  mixins: [entityCRUD],
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.user.userOrgJob,
      autoStartSearch: false,
      initColumns: [
        {
          title: '#',
          align: 'center',
          scopedSlots: { customRender: 'serial' },
          width: 30,
        },
        {
          title: '部门名称',
          dataIndex: 'organizationFullName',
          align: 'center',
          key: 'organizationFullName',
          width: 200,
        },
        {
          title: '职务',
          dataIndex: 'jobName',
          align: 'center',
          key: 'jobName',
          width: 200,
          scopedSlots: { customRender: 'job' },
        },
        {
          title: '默认',
          scopedSlots: { customRender: 'ifDefault' },
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 160,
          align: 'center',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      currentOrgIds: [],
    }
  },
  methods: {
    /*更新默认职务*/
    handleDefault(id) {
      this.$http(this, {
        url: SERVICE_URLS.user.userOrgJob.updateDefault,
        loading: 'tableLoading',
        params: {
          id: id,
        },
        noTips: true,
        success: (data) => {
          this.search()
        },
      })
    },
    search() {
      this.$http(this, {
        url: SERVICE_URLS.user.userOrgJob.search,
        noTips: true,
        data: { id: this.chooseData.id },
        loading: 'tableLoading',
        success: (data) => {
          this.tableData = data.body
          const temp = []
          this.tableData.forEach((x) => {
            temp.push(x.organizationId)
          })
          this.currentOrgIds = temp
        },
      })
    },
    handleDelete(id) {
      this.$http(this, {
        url: SERVICE_URLS.user.userOrgJob.delete,
        params: {
          id: id,
        },
        successTipsContent: '组织机构职位关系删除成功',
        success: () => {
          this.search()
          this.$emit('deleteSuccess', id)
        },
      })
    },
    addSuccess(data) {
      this.confirmButtonStatus = false
      this.addModal = false
      this.search()
      this.$emit('addSuccess', data)
    },
    editSuccess(data) {
      //this.editLoading = false
      this.confirmButtonStatus = false
      this.editModal = false
      this.search()
      this.$emit('editSuccess', data)
    },
  },
}
</script>
