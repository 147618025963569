<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="用户名" v-if="type === 'add'">
            <a-input
              v-decorator="[
                'username',
                {
                  rules: [
                    { type: 'string', required: true, message: '登录名称不能为空' },
                    { type: 'string', max: 20, message: '登录名称不能超过30个字符' },
                  ],
                  trigger: 'change',
                },
              ]"
              placeholder="输入用户名"
            ></a-input>
          </a-form-item>
          <a-form-item label="用户名" v-if="type === 'edit'">
            {{ this.formItem.username }}
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="手机号">
            <a-input
              v-decorator="[
                'phone',
                {
                  rules: [{ type: 'string', pattern: /^1[0-9]{10}$/, required: true, message: '手机号不合法' }],
                  trigger: 'change',
                },
              ]"
              placeholder="输入手机号"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="用户姓名">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [
                    { type: 'string', required: true, message: '用户姓名不能为空' },
                    { type: 'string', max: 30, message: '用户姓名不能超过30个字符' },
                  ],
                  trigger: 'change',
                },
              ]"
              placeholder="输入用户姓名"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="身份证号">
            <a-input
              v-decorator="[
                'idCard',
                {
                  rules: [
                    { type: 'string', required: false, message: '身份证号不能为空' },
                    { type: 'string', max: 30, message: '身份证号不能超过30个字符' },
                  ],
                  trigger: 'change',
                },
              ]"
              placeholder="输入身份证号"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24" v-if="type === 'add'">
          <a-form-item label="密码" :required="true">
            <a-input
              type="Password"
              placeholder="请输入密码"
              v-decorator="['password', { rules: [{ required: true, message: '请输入新密码' }] }]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24" v-if="type === 'add'">
          <a-form-item label="确认密码" :required="true">
            <a-input
              type="Password"
              placeholder="请输入确认密码"
              v-decorator="['confirmPassword', { rules: [{ required: true, message: '请输入确认密码' }] }]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="性别：">
            <a-radio-group
              :options="GenderType"
              v-decorator="[
                'gender',
                {
                  initialValue: 'secrecy',
                  rules: [{ type: 'string', required: true, message: '性别为必填项' }],
                  trigger: 'change',
                },
              ]"
            ></a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="用户类型：">
            <a-radio-group
              :options="UserType"
              v-decorator="[
                'userType',
                {
                  initialValue: 'user',
                  rules: [{ type: 'string', required: true, message: '用户类型为必填项' }],
                  trigger: 'change',
                },
              ]"
            ></a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="用户状态：">
            <a-radio-group
              :options="UserStatus"
              v-decorator="[
                'status',
                {
                  initialValue: 'normal',
                  rules: [{ type: 'string', required: true, message: '用户状态为必填项' }],
                  trigger: 'change',
                },
              ]"
            ></a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider>用户部门信息</a-divider>
      <template v-for="(k, index) in form.getFieldValue('keys')">
        <a-row :key="'org_job_' + k" class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item :label="'选择部门' + (index + 1)">
              <a-tree-select
                style="width: 100%;"
                v-decorator="[
                  `organizations[${k}]`,
                  {
                    validateTrigger: ['change'],
                    rules: [
                      {
                        required: true,
                        message: '请选择组织机构',
                      },
                      {
                        validator: orgValidator,
                        message: '请检查所选部门，同一用户在同一单位下只允许设置一个部门',
                      },
                    ],
                  },
                ]"
                :treeData="organizations"
                treeDefaultExpandAll
                treeNodeFilterProp="title"
                @change="handleChooseOrgChange($event, index)"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                placeholder="请选择组织机构"
              ></a-tree-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="选择职务">
              <a-select
                v-decorator="[`jobs[${k}]`]"
                allowClear
                showSearch
                style="width: 350px;"
                placeholder="请选择职务"
                optionFilterProp="title"
              >
                <a-spin v-if="jobs[index].fetching" style="margin-left: 150px;" slot="notFoundContent" size="small" />
                <a-select-option :key="item.key" :value="item.key" :title="item.value" v-for="item in jobs[index].data">
                  {{ item.value }}
                </a-select-option>
              </a-select>
              <a-icon
                v-if="form.getFieldValue('keys').length > 1"
                class="dynamic-delete-button m-l-12"
                type="minus-circle-o"
                :disabled="form.getFieldValue('keys').length === 1"
                @click="() => removeOrgJob(index, k)"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </template>
      <a-row class="form-row m-b-24" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24" style="text-align: center;">
          <a-button type="dashed" style="width: 40%;" @click="addOrgJob">
            <a-icon type="plus" />
            增加部门
          </a-button>
        </a-col>
      </a-row>
      <a-alert message="同一用户在同一单位下只允许设置一个部门" banner />
      <a-divider>用户拓展信息</a-divider>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="微信">
            <a-input v-decorator="['wechat']" placeholder="输入微信"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="QQ">
            <a-input v-decorator="['qq']" placeholder="输入QQ"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="邮箱">
            <a-input
              v-decorator="['email', { rules: [{ type: 'email', message: '邮箱不合法' }], trigger: 'change' }]"
              placeholder="输入邮箱"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="电话">
            <a-input
              v-decorator="[
                'tel',
                { rules: [{ type: 'string', max: 20, message: '电话不能超过30个字符' }], trigger: 'change' },
              ]"
              placeholder="输入电话"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="16" :md="16" :sm="24">
          <a-form-item label="箴言">
            <a-input v-decorator="['motto']" rows="4" placeholder="请输入个人箴言" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="个人介绍">
            <a-textarea v-decorator="['introduction']" rows="3" placeholder="请输入个人介绍"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="家庭地址">
            <a-textarea v-decorator="['address']" rows="3" placeholder="请输入家庭地址"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
import SERVICE_URLS from '../../../api/service.url'

import orgValidator from './mixins/org-validator'

import { UserDetailVO, UserDTO, GenderType, UserType, UserStatus } from '../../../api/vo/settings/userDomain'

let id = 0

export default {
  name: 'UserForm',
  props: {
    type: {
      type: String,
      default: 'add',
    },
  },
  mixins: [orgValidator],
  data() {
    return {
      spinShow: false,
      formItem: UserDetailVO(),
      formItemDTO: UserDTO(),
      GenderType: GenderType,
      UserType: UserType,
      UserStatus: UserStatus,
      organizations: [],
      // 用户目前拥有的部门信息
      // 数据结构 {orgId:'',jobId:''} 其中 orgId不可为空
      organizationsJobs: [],
      jobs: [],
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
  },
  created() {
    if (this.type === 'add') {
      this.addOrgJob()
    }
  },
  methods: {
    loadData(id) {
      this.formItem = UserDetailVO()
      this.form.resetFields()
      this.$http(this, {
        url: SERVICE_URLS.user.view,
        params: { id },
        loading: 'spinShow',
        noTips: true,
        success: (data) => {
          this.setFields(data.body)
        },
      })
    },
    // 加载部门数据
    loadOrgSelect() {
      /*部门列表*/
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        data: {
          showUnit: true,
        },
        loading: 'spinShow',
        noTips: true,
        success: (data) => {
          this.organizations = data.body
        },
      })
    },
    // 添加
    submitAdd() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setDTOFields(values)
          this.$emit('submitStart')
          this.$http(this, {
            url: SERVICE_URLS.user.create,
            data: this.formItemDTO,
            loading: 'spinShow',
            success: (data) => {
              //清空表单数据
              this.formItem = UserDetailVO()
              this.formItemDTO = UserDTO()
              this.form.resetFields()
              this.$emit('addSuccess', data.body)
            },
            error: () => {
              this.$emit('addError')
            },
          })
        }
      })
    },
    setDTOFields(values) {
      this.formItemDTO.name = values.name
      this.formItemDTO.username = values.username
      this.formItemDTO.idCard = values.idCard
      this.formItemDTO.password = values.password
      this.formItemDTO.gender = values.gender
      this.formItemDTO.userType = values.userType
      this.formItemDTO.status = values.status
      this.formItemDTO.phone = values.phone
      this.formItemDTO.tel = values.tel
      this.formItemDTO.motto = values.motto
      this.formItemDTO.wechat = values.wechat
      this.formItemDTO.qq = values.qq
      this.formItemDTO.email = values.email
      this.formItemDTO.introduction = values.introduction
      this.formItemDTO.address = values.address
      // 组织机构 - 职务信息处理
      this.formItemDTO.organizationJobs = []
      for (let i = 0, length = values.organizations.length; i < length; i++) {
        const oId = values.organizations[i]
        if (oId) {
          this.formItemDTO.organizationJobs.push({
            organizationId: oId,
            jobId: values.jobs[i],
          })
        }
      }
    },
    setFields(body) {
      let content = body.content
      Object.assign(this.formItem, content)
      this.organizations = body.select
      this.form.setFieldsValue({
        name: this.formItem.name,
        phone: this.formItem.phone,
        idCard: this.formItem.idCard,
        gender: this.formItem.gender,
        userType: this.formItem.userType,
        status: this.formItem.status,
        tel: this.formItem.tel,
        motto: this.formItem.motto,
        wechat: this.formItem.wechat,
        qq: this.formItem.qq,
        email: this.formItem.email,
        introduction: this.formItem.introduction,
        address: this.formItem.address,
      })
      //设置组织机构选中
      content.organizationJobs.forEach((x) => {
        this.addOrgJob()
      })
      this.$nextTick(() => {
        //设置对应表单值
        this.form.getFieldValue('keys').forEach((k, index) => {
          let fieldName = 'organizations[' + k + ']'
          this.form.setFieldsValue({
            [fieldName]: content.organizationJobs[index].organizationId,
          })
          this.jobs[index].data = body.jobs['org_' + content.organizationJobs[index].organizationId]
          //设置职位对应值
          let fieldJobName = 'jobs[' + k + ']'
          this.form.setFieldsValue({
            [fieldJobName]: content.organizationJobs[index].jobId || undefined,
          })
        })
      })
    },
    submitEdit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setDTOFields(values)
          this.$emit('submitStart')
          this.$http(this, {
            url: SERVICE_URLS.user.update,
            data: this.formItemDTO,
            params: {
              id: this.formItem.id,
            },
            loading: 'spinShow',
            success: (data) => {
              this.$emit('editSuccess', data.body)
            },
            error: () => {
              this.$emit('editError')
            },
          })
        }
      })
    },
    // 多部门操作
    addOrgJob() {
      const { form } = this
      // can use data-binding to get
      this.jobs.push({
        fetching: false,
        data: [],
      })
      const keys = form.getFieldValue('keys')
      const nextKeys = keys.concat(++id)
      // can use data-binding to set
      // important! notify form to detect changes
      form.setFieldsValue({
        keys: nextKeys,
      })
    },
    removeOrgJob(index, k) {
      const { form } = this
      // can use data-binding to get
      const keys = form.getFieldValue('keys')
      // We need at least one passenger
      if (keys.length === 1) {
        return
      }
      this.jobs.splice(index, 1)
      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter((key) => key !== k),
      })
      this.$nextTick(() => {
        form.validateFields(['organizations'], { force: true })
      })
    },
    // 获取部门下已配置权限的职务信息
    handleChooseOrgChange(value, index) {
      this.jobs[index].fetching = true
      this.jobs[index].data = []
      this.$http(this, {
        url: SERVICE_URLS.earlBase.job.selectByOrg,
        data: {
          orgId: value,
        },
        loading: 'no',
        noTips: true,
        success: (data) => {
          this.jobs[index].fetching = false
          this.jobs[index].data = data.body
        },
        error: () => {
          this.jobs[index].fetching = false
        },
      })
    },
    getChooseOrgIds(value) {
      return this.form.getFieldValue('organizations')
    },
    getOrganizationsData() {
      return this.organizations
    },
  },
}
</script>
