export const UserDetailVO = function () {
  return {
    id: null,
    name: '', //昵称
    no: '',
    idCard: '',
    tel: '',
    phone: '',
    phoneVerify: '',
    username: '',
    introduction: '',
    password: '',
    email: '',
    emailVerify: '',
    qq: '',
    wechat: '',
    motto: '',
    address: '',
    birthday: null,
    headImg: '',
    headImgYuan: '',
    status: 'normal',
    userType: 'user',
    gender: 'man',
    creater: '',
    dateCreated: '',
    lastUpdated: '',
    organizationJobs: []
  }
}

export const UserDTO = function () {
  return {
    id: null,
    name: '', //昵称
    no: '',
    idCard: '',
    tel: '',
    phone: '',
    username: '',
    introduction: '',
    birthday: null,
    password: '',
    email: '',
    qq: '',
    wechat: '',
    motto: '',
    address: '',
    headImg: '',
    headImgYuan: '',
    status: 'normal',
    userType: 'user',
    gender: 'man',
    organizationJobs: []
  }
}

export const GenderType = [
  { value: 'man', label: '男' },
  { value: 'woman', label: '女' },
  { value: 'secrecy', label: '保密' }
]
export const UserType = [
  { value: 'admin', label: '管理员' },
  { value: 'user', label: '普通用户' }
  // { value: 'dev', label: '开发管理员' }
]
export const UserStatus = [
  { value: 'normal', label: '正常状态' },
  { value: 'blocked', label: '封禁状态' }
]