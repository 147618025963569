<template>
  <a-spin :spinning="spinShow">
    <a-form layout="vertical" :form="form">
      <a-form-item label="组织机构">
        <a-tree-select
          v-if="type === 'add'"
          v-decorator="[
            'organizationId',
            {
              validateTrigger: ['change'],
              rules: [
                {
                  required: true,
                  message: '请选择组织机构',
                },
                {
                  validator: orgValidator,
                  message: '请检查所选部门，同一用户在同一单位下只允许设置一个部门',
                },
              ],
            },
          ]"
          :treeData="organizations"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          @change="handleChooseOrgChange"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择组织机构"
        ></a-tree-select>
        <template v-if="type !== 'add'">
          {{ this.formItem.organizationFullName }}
        </template>
      </a-form-item>
      <a-form-item label="职务">
        <a-select
          showSearch
          allowClear
          optionFilterProp="title"
          style="width: 100%;"
          placeholder="请选择职务"
          v-decorator="['jobId']"
        >
          <a-spin v-if="jobsFetching" style="margin-left: 150px;" slot="notFoundContent" size="small" />
          <a-select-option :key="item.key" :value="item.key" :title="item.value" v-for="item in jobs">
            {{ item.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import SERVICE_URLS from '../../../api/service.url'

import orgValidator from './mixins/org-validator'

let formItem = () => {
  return {
    id: null,
    organizationId: undefined,
    organizationName: '',
    organizationFullName: '',
    jobId: undefined,
    jobName: '',
    ifDefault: undefined,
  }
}

let formDTOItem = () => {
  return {
    id: null,
    userId: null,
    organizationId: undefined,
    ifDefault: undefined,
    jobId: undefined,
  }
}

export default {
  name: 'UserJobForm',
  props: {
    type: {
      type: String,
      default: 'add',
    },
    userId: Number,
    chooseOrgIds: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  mixins: [orgValidator],
  watch: {
    chooseOrgIds(val) {
      this.currentChooseOrgIds = val
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      formDTOItem: formDTOItem(),
      formItem: formItem(),
      spinShow: false,
      jobsFetching: false,
      organizations: [],
      jobs: [],
      currentChooseOrgIds: this.chooseOrgIds,
    }
  },
  created() {
    if (this.type === 'add') {
      this.loadOrgSelect()
    }
  },
  methods: {
    loadData(id) {
      this.form.resetFields()
      this.formItem = formItem()
      this.$http(this, {
        url: SERVICE_URLS.user.userOrgJob.view,
        loading: 'spinShow',
        params: { id },
        noTips: true,
        success: (data) => {
          this.setFields(data.body)
        },
      })
    },
    setFields(data) {
      Object.assign(this.formItem, data.content)
      this.jobs = data.select
      if (this.type === 'add') {
        this.form.setFieldsValue({
          organizationId: this.formItem.organizationId,
        })
      }
      this.form.setFieldsValue({
        jobId: this.formItem.jobId || undefined,
      })
    },
    // ↓获取部门列表
    loadOrgSelect() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        data: {
          showUnit: true,
        },
        loading: 'spinShow',
        noTips: true,
        success: (data) => {
          this.organizations = data.body
        },
      })
    },
    // 获取部门下已配置权限的职务信息
    handleChooseOrgChange(value) {
      this.jobs = []
      this.$http(this, {
        url: SERVICE_URLS.earlBase.job.selectByOrg,
        data: {
          orgId: value,
        },
        loading: 'jobsFetching',
        noTips: true,
        success: (data) => {
          this.jobs = data.body
        },
      })
    },
    submitAdd() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.formDTOItem.userId = this.userId
          this.formDTOItem.jobId = values.jobId
          this.formDTOItem.organizationId = values.organizationId
          this.$emit('submitStart')
          this.$http(this, {
            url: SERVICE_URLS.user.userOrgJob.create,
            data: this.formDTOItem,
            success: (data) => {
              //添加成功，清空表单数据，并触发回调
              this.$emit('addSuccess', data.body)
              this.form.resetFields()
            },
            error: () => {
              this.$emit('addError', 'httpError')
            },
          })
        }
      })
    },
    submitEdit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.formDTOItem.jobId = values.jobId
          this.$emit('submitStart')
          this.$http(this, {
            url: SERVICE_URLS.user.userOrgJob.update,
            data: this.formDTOItem,
            params: { id: this.formItem.id },
            noTips: true,
            success: (data) => {
              //添加成功，清空表单数据，并触发回调
              this.$emit('editSuccess', data.body)
              //清空表单数据
              this.formItem = formItem()
              this.form.resetFields()
            },
            error: () => {
              this.$emit('editError', 'httpError')
            },
          })
        }
      })
    },
    getChooseOrgIds(value) {
      const temp = JSON.parse(JSON.stringify(this.currentChooseOrgIds))
      temp.push(value)
      return temp
    },
    getOrganizationsData() {
      return this.organizations
    },
  },
}
</script>
